import styled from "styled-components"
import { PopupModal } from "../../../components/TopBar/PopupModal/PopupModal"
import { useState } from "react"
import { AddToShipment } from "../../Allocation/AddToShipment";
import { UpdateStatus } from "../../Allocation/UpdateStatus";
import { useUpdateDispatchPurchaseAllocationsMutation } from "../../../../../store/apiSlice/orderApiSlice";
import { useNotification } from "../../../../../hooks/useNotification";


export const ListMenuOptions = ({ closeModalMenuModal, allocation, selectedOrderFromListview, setSelectedOrder }) => {
    const [isOpenAddToShipmentModal, setIsOpenAddToShipmentModal] = useState(false);
    const [isOpenUpdateStatusModal, setIsOpenUpdateStatusModal] = useState(false);
    const [updateDispatchQtyForPurchaseAllocation] = useUpdateDispatchPurchaseAllocationsMutation();
    const [updateDispatchQtyForTransferAllocation] = useUpdateDispatchPurchaseAllocationsMutation();

    const notify = useNotification();
    const openAddToShipmentModal = () => {
        setIsOpenAddToShipmentModal(true)
    }
    const closeAddToShipmentModal = () => {
        setIsOpenAddToShipmentModal(false);
        closeModalMenuModal()
    }
    const closeUpdateStatusModal = () => {
        setIsOpenUpdateStatusModal(false);
        closeModalMenuModal()
    }
    const handleUpdateShipment = async () => {
        let res = null;
        try {
            if (allocation === 'purchase') {
                res = await updateDispatchQtyForPurchaseAllocation({ ids: selectedOrderFromListview }).unwrap()
            }
            else {
                res = await updateDispatchQtyForTransferAllocation({ ids: selectedOrderFromListview }).unwrap()
            }

            notify({
                type: "success",
                message: res.detail,
            });
        } catch (error) {
            if (error.data?.detail) {
                notify({ type: "error", message: error.data.detail });
            }
            notify({ type: "error", message: 'Something went wrong' });

        }
        setSelectedOrder([])
        closeModalMenuModal();
    }
    return (
        <PopupModal styleOfModal={styleOfPopup} closeModal={closeModalMenuModal}>

            {isOpenAddToShipmentModal &&
                <AddToShipment closeModal={closeAddToShipmentModal} allocation={allocation} ordersName={selectedOrderFromListview} setSelectedOrder={setSelectedOrder} />
            }
            {isOpenUpdateStatusModal &&
                <UpdateStatus closeModal={closeUpdateStatusModal} allocation={allocation} ordersName={selectedOrderFromListview} setSelectedOrder={setSelectedOrder} />
            }
            <ListMenuOptionsCss >
                <ul>
                    <li className={`optionItem ${selectedOrderFromListview.length > 0 ? '' : 'disableItem'} `} onClick={openAddToShipmentModal}>Add to Shipment </li>
                    <li className={`optionItem ${selectedOrderFromListview.length > 0 ? '' : 'disableItem'} `} onClick={() => setIsOpenUpdateStatusModal(true)}>Update Status </li>
                    <li className={`optionItem ${selectedOrderFromListview.length > 0 ? '' : 'disableItem'} `} onClick={handleUpdateShipment}>Update Shipment </li>
                    <li className={`optionItem ${selectedOrderFromListview.length > 0 ? '' : 'disableItem'} `} onClick={() => ''}>Split </li>
                </ul>
            </ListMenuOptionsCss>
        </PopupModal>
    )
}

export const ListMenuOptionsCss = styled.div`
    display: flex; 
    padding: 4px 20px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid var(--PEARL, #ecf1f4);
    background-color: var(--Conditional-pop-over, #fff); 

    /* drop-shadow/0.12+0.8+0.5 */
    box-shadow:
        0px 3px 6px -4px rgba(0, 0, 0, 0.12),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 9px 28px 8px rgba(0, 0, 0, 0.05);

        ul>li{
            list-style: none;
        }
       ul >.optionItem{ 
            padding: 5px 12px;
            color: var(--DARK-GREY, #0e0e2c);
            font-weight: 400;
            line-height: 22px; 
            >a{
                color: var(--DARK-GREY, #0e0e2c);
            }

            &:hover {
            color: #1249bf;
            cursor: pointer;
        }
    }
    .optionItem.selectedItem{
            color: #1249bf;

        }
        ul>.disableItem{
            cursor: text;
            pointer-events: none;
            color: rgba(0, 0, 0, 0.25);
            a{
                color: rgba(0, 0, 0, 0.25);
                pointer-events: visible;
            }
        }
    
`

export const styleOfPopup = { position: 'absolute', top: '6.5vh', right: 24 }